import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import locales from "../../../constants";

const GardaSikring = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "en";
  return (
    <Layout
      seo={{
        title: "Garda Sikring",
        href: slug,
        lang: "en",
      }}
      header={{
        background:
          "linear-gradient( 45deg, #ec2f22, #e22e22, #d72c20, #d92b20, #d02a1f )",
        icons: "#ec2f22",
        navClass: "garda",
        ogImage: require("../../../assets/img/portfolio/garda_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/pl/projekty/garda-sikring/",
      }}
    >
      <PortfolioHeader name="garda" height="214" />
      <section className="container-fluid garda-section-1" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Garda Sikring</h1>
              <ul>
                <li>Websites</li>
                <li>System integrations</li>
                <li>Advanced contact forms with geolocalization</li>
                <li>Email signatures</li>
                <li>Ongoing website administration & updates</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                Garda Sikring is the Norwegian market leader in perimeter
                security. The company provides and installs permanent and
                temporary solutions, such as barriers, gates, nets, fences, etc.
                Our cooperation has been going on since the beginning of 2019
                and during this time we have developed a dozen websites
                together. We have also been responsible for integrations with
                the CRM system used by the company, design and development of
                the advanced contact forms with geolocalization, and e-mail
                signatures.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid garda-section-2">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="scroll_wrapper">
              <div className="img_wrapper">
                <img
                  src={require("../../../assets/img/portfolio/garda_full.png")}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid garda-section-3">
        <div className="row">
          <div className="col-lg-5 offset-lg-1">
            <img
              src={require("../../../assets/img/portfolio/garda_main_1.png")}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="col-lg-4 offset-lg-1">
            <div className="inner">
              <h3>Websites</h3>
              <p>
                We have designed the company's new website. It shows a wide
                range of the company's services in the field of perimeter
                security, contains an extensive product catalogue, and contact
                forms have been integrated with the client's CRM system to
                increase the quality and speed of delivering the company's
                services. We are constantly developing additional features in
                accordance with the current needs of our client.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid garda-section-4">
        <div className="row">
          <div className="col-md-10 text-right">
            <img
              src={require("../../../assets/img/portfolio/garda_main_2.png")}
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="container-fluid garda-section-5">
        <div className="row">
          <div className="col-lg-4 offset-lg-1">
            <div className="inner">
              <p>
                In accordance with the client's needs, we have also designed
                websites of the subsidiaries of the Garda Sikring holding, like
                Nor-Netting AS or Trygg Vei AS, consistent with the visual
                identification of the whole brand, which allowed us to optimize
                the work of individual company branches in selected regions of
                Norway.
              </p>
            </div>
            <img
              src={require("../../../assets/img/portfolio/garda_main_3.png")}
              className="img-fluid img_main_3"
              alt=""
            />
          </div>
          <div className="col-lg-5 offset-lg-2">
            <img
              src={require("../../../assets/img/portfolio/garda_main_4.png")}
              className="img-fluid img_main_4"
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="container-fluid garda-section-6">
        <div className="row">
          <div className="col-lg-4 offset-lg-1">
            <div className="inner">
              <h3>Email signatures</h3>
              <p>
                We have designed e-mail signatures, so that the daily
                correspondence is consistent with the visual identification of
                the entire brand and shows potential contractors that the market
                leader, which Garda Sikring undoubtedly is, cares about every
                single detail, both in terms of its image and customer service.
              </p>
            </div>
          </div>
          <div className="col-lg-5 offset-lg-1">
            <img
              src={require("../../../assets/img/portfolio/garda_main_5.png")}
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="container-fluid garda-section-7">
        <div className="inner">
          <h3>Ongoing website administration & updates</h3>
          <p>
            We are currently managing the company's website as part of the
            services offered under ForSecure (
            <a href="https://forsecure.pl/" target="_blank" rel="noreferrer">
              www.forsecure.pl
            </a>
            ), which consists of the website's regular updates, security, as
            well as ongoing maintenance, and further development of the website
            with new functionalities, at the request of our client.
          </p>
          <img
            src={require("../../../assets/img/forsecure.png")}
            className="img-fluid"
            alt=""
          />
        </div>
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default GardaSikring;
